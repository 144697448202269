input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.logo-aliado {
    width: 50%;
    height: 50%;
}

.logo-cupones {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
